.light{
  --text-color: var(--black);
  --bg-color: var(--white);
}

.neutral{
  --text-color: var(--black);
  --bg-color: var(--beige);
}

.dark{
  --text-color: var(--white);
  --bg-color: var(--sauge);
}

.wrapper{
  display: flex;
  width: 100%;
  align-items: center;
  background: var(--bg-color);
}

.container {
  --padding-container: var(--spacing-80) var(--spacing-16);
  display: flex;
  flex-flow: column wrap;
  gap: var(--spacing-16);
  background: var(--bg-color);
  color: var(--text-color);
  padding: var(--padding-container);
  text-align: center;
  align-items: center;
  flex: 1;
}

.title{
  font: var(--font-h1-desktop-default);
  font-weight: 800;
}

.subtitle{
  position: relative;
  font: var(--font-h4-desktop-default);
  font-weight: 800;
}

.subtitle::before{
  --size: 12px;
  content: "";
  position: absolute;
  left: 50%;
  bottom:-1.5rem;
  transform: translate(-50%, 50%);
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  background: var(--yellow);
}

.containerImg{
  --size-img: 200px;
  position: relative;
  width: 200px;
  height: 200px;
}

.containerImg img {
  height: auto !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
}

@media (max-width: 1024px) {
  .container{
    --padding-container: var(--spacing-40) var(--spacing-24);
  }

  .title{
    font: var(--font-h1-mobile-default);
    font-weight: 800;
    
  }

  .subtitle{
    font: var(--font-h4-mobile-default);
    font-weight: 800;
  }
}

@media (max-width: 768px) {
  .container{
    --padding-container: var(--spacing-40) 0;
    justify-content: center;
  }

  .containerImg{
    --size-img: 150px;
    width: 150px;
    height: 150px;
  }

  .subtitle::before{
    display: none;
  }
}